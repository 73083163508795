html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

body {
	font: 18px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	color: #444;
  font-size: .875rem;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

h1, h2, h3, h4, h5 {
  cursor: default;
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.button {
  background-color: #184fe6; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  /* background-color: hsl(210, 11%, 15%); */
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25); */
} 

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.faded-red {
  color: #ef9e9e
}

.alert {
  display:inline-block;
}

.file-upload-component {
  border: 1px solid lightgrey;
  border-radius: 1em;
  padding: 5px;
  margin: 5px; 
  position: relative;
  height: max-content;
  block-size: max-content;
}

.file-upload-component .open-file-dialog {
  margin: 0;
  left: 10px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  /* position: absolute; */
  /* height: 100%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
}

.filesize {
  color: rgb(104, 104, 104);
}

.upload-files {
  margin: 0;
  left: 10px;
  position: relative;
  margin-bottom: 5px;
}

.annotation-metadata:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
  background-color: #2196F3;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:focus + .switch-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch-slider.round {
  border-radius: 34px;
}

.switch-slider.round:before {
  border-radius: 50%;
}


.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

svg text {
    font-family:optima;
}

svg text .title {
    font-size: 2em;
}

.lifespan .outside_brush {
    fill-opacity: 0.1
}

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 2px;
}


.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

ul li {
  list-style-type: none;
}

.vertical-slider {
  -webkit-appearance: slider-vertical
}


.slice-slider {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}

/* .slice-slider::-webkit-slider-thumb  {
  -webkit-appearance: none;
} */

.slice-slider:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.slice-slider::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
.slice-slider::-webkit-slider-thumb {
  /* -webkit-appearance: none; */
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background: rgb(0, 117, 255);
  cursor: pointer;
  margin-top: -5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
}

/* All the same stuff for Firefox */
.slice-slider::-moz-range-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 9px;
  background: rgb(0, 117, 255);
  cursor: pointer;
}

/* All the same stuff for IE */
.slice-slider::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background: rgb(0, 117, 255);
  cursor: pointer;
}

.slice-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  background: #C9E2FD;
  border-radius: 4px;
  border: 0.2px solid #010101;
}

.slice-slider:focus::-webkit-slider-runnable-track {
  background: #C9E2FD;
}

.slice-slider::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  background: #C9E2FD;
  border-radius: 4px;
  border: 0.2px solid #010101;
}

.slice-slider::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.slice-slider::-ms-fill-lower {
  background: #C9E2FD;
  border: 0.2px solid #010101;
  border-radius: 4px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
}
.slice-slider:focus::-ms-fill-lower {
  background: #C9E2FD;
}
.slice-slider::-ms-fill-upper {
  background: #C9E2FD;
  border: 0.2px solid #010101;
  border-radius: 4px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
}
.slice-slider:focus::-ms-fill-upper {
  background: #C9E2FD;
}

.patient-timeline {
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(45%, rgba(255,255,255,0)), color-stop(51%, rgba(191,128,11,1)), color-stop(57%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,0)));
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 45%, rgba(191,128,11,1) 51%, rgba(255,255,255,0) 57%, rgba(255,255,255,0) 100%);
}

